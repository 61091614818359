import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';

import Card from 'ui/components/Card';
import { API } from 'API';
import { Urls } from 'urls';

import './DocumentVerificationViewer.scss';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

const dateFns = new DateFnsUtils();

export default class DocumentVerificationViewer extends React.Component {
  static url = Urls.documentVerificationViewer;
  static contextTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  static propTypes = {
    title: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    const { state } = this.context.location;

    const params = new URLSearchParams(window.location.search);

    this.state = {
      sessionInfo: {},
      pdfUrl: '',
      videoUrl: '',
      needsRedirect: false,
      errorMessage: '',

      documentId: params.get('documentId'),
      pin: state ? state.pin : '',
    };

    this.collectInfo();

    context.history.replace({
      pathname: context.location.pathname,
      search: context.location.search,
      state: {},
    });
  }

  componentDidMount() {
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }

  collectInfo = async () => {
    const { documentId, pin } = this.state;

    try {
      const sessionData = await API.getSessionInfoWithPin(documentId, pin);

      this.setState({
        pdfUrl: sessionData.data.documentUrl,
        videoUrl: sessionData.data.videoUrl,
        sessionInfo: sessionData.data.session,
      });
    } catch (response) {
      const errorMessage = response.getErrorMessage({
        document_not_found: 'The document could not be found.',
        document_not_accessible: 'Invalid document ID or PIN.',
        excessive_failed_attempts:
          'Too many download attempts for this document. Please contact support.',
      });

      this.setState({ needsRedirect: true, errorMessage });
    }
  };

  renderVideo = () => (
    <div className="info-wrapper">
      <div>
        <span className="info-wrapper__header">Session Evidence</span>
        <span>
          <video className="video" controls>
            <source src={this.state.videoUrl} type="video/mp4" />
          </video>
        </span>
      </div>
    </div>
  );

  renderSessionInfo = () => {
    const { sessionInfo } = this.state;
    const sessionKeys = [
      ['Notary Name', 'notaryName'],
      ['Signer Name', 'signerName'],
      ['Witness 1 Name', 'witness1Name'],
      ['Witness 2 Name', 'witness2Name'],

      // This won't be used as we have to apply transformations to it, so no friendly name needed
      ['_', 'dateSigned'],
    ];
    const sessionInfoHasAllKeys = sessionKeys.every(item => Boolean(sessionInfo[item[1]]));

    if (!sessionInfoHasAllKeys) {
      return null;
    }

    // Declared down here so as to avoid running an undefined value through
    const dateString = dateFns.format(
      sessionInfo.dateSigned * 1000,

      // e.g. February 14th, 2019 4:31:29 PM
      // https://date-fns.org/docs/format
      'MMMM do, yyyy h:mm:ss a',
    );

    return (
      <div className="info-wrapper">
        {sessionKeys.slice(0, -1).map(([friendlyName, key], index) => (
          <div key={index}>
            <span className="info-wrapper__header">{friendlyName}:</span>
            <span className="info-wrapper__body">{sessionInfo[key]}</span>
          </div>
        ))}

        {/* Keeping this generic because Mitek can accept other forms of ID than just driver's licenses */}
        <div>
          <div className="info-wrapper__header">Type of ID:</div>
          <div className="info-wrapper__body">Government Issued Photo ID</div>
        </div>

        <div>
          <span className="info-wrapper__header">Date and Time of Signing:</span>
          <span className="info-wrapper__body">{dateString}</span>
        </div>
      </div>
    );
  };

  render() {
    const { pdfUrl, videoUrl, sessionInfo, needsRedirect, errorMessage } = this.state;

    // Redirect on API error
    if (needsRedirect) {
      return (
        <Redirect
          to={{
            pathname: Urls.documentVerificationLanding,
            state: { errorMessage },
          }}
        />
      );
    }

    // Wait for the promise to resolve
    if (sessionInfo === null) {
      return null;
    }

    const dateRevoked = sessionInfo.revokedAt
      ? dateFns.format(sessionInfo.revokedAt * 1000, 'MMMM do, yyyy')
      : null;

    return (
      <main id="main-content" className="storage-wrapper">
        <Card className="card--storage storage">
          {pdfUrl && <iframe src={pdfUrl} frameBorder="0" className="left" />}
          <div className="right">
            <div className="right__top">
              <Link to={Urls.documentVerificationLanding}>
                <div className="right__close" />
              </Link>

              {dateRevoked && (
                <p className="revoked-message">
                  {sessionInfo.signerName} revoked these documents on {dateRevoked}.
                </p>
              )}

              {videoUrl && this.renderVideo()}
              {this.renderSessionInfo()}
            </div>
          </div>
        </Card>
      </main>
    );
  }
}
