import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

import PanelTitle from '@wui/layout/panelTitle';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Panel from '@wui/layout/panel';

const styles = theme => ({
  panelTitle: {
    margin: [0, '!important'],
    width: ['auto', '!important'],
    borderBottom: ['none', '!important'],
  },
  grid: {
    [theme.breakpoints.desktop]: {
      padding: [[0, 8]],
    },
  },
  thirdColTitle: {
    textAlign: 'right',
  },
  screenReaderOnly: {
    position: ['absolute', '!important'],
    width: '1px',
    height: '1px',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    border: 0,
    clip:
      'rect(1px 1px 1px 1px)' /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */,
    clipPath: 'inset(50%)' /*modern browsers, clip-path works inwards from each corner*/,
    whiteSpace:
      'nowrap' /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */,
  },
});

class TableHeader extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    firstLabel: PropTypes.string,
    secondLabel: PropTypes.string,
    thirdLabel: PropTypes.string,
    thirdColLink: PropTypes.string,
    showThirdColTitle: PropTypes.bool,
  };

  static defaultProps = {
    firstLabel: 'NEXT STEPS',
    secondLabel: 'STATUS',
    thirdLabel: 'ACTIONS',
    thirdColLink: '',
    showThirdColTitle: false,
  };

  render() {
    const {
      classes,
      firstLabel,
      secondLabel,
      thirdLabel,
      thirdColLink,
      showThirdColTitle,
    } = this.props;

    return (
      <Panel role="row" tableRow paddingless>
        <PanelTitle className={classes.panelTitle} simple>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={5}
            className={classes.grid}
            aria-label=""
          >
            <Grid item xs={8} sm={6} role="columnheader">
              <Typography variant="caption" bold component="div">
                {firstLabel}
              </Typography>
            </Grid>

            <Hidden only="xs">
              <Grid item sm={3} role="columnheader">
                <Typography variant="caption" bold component="div">
                  {secondLabel}
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={4} sm={3} align="center" role="columnheader">
              <Typography
                variant="caption"
                bold
                component="div"
                className={!showThirdColTitle ? classes.screenReaderOnly : classes.thirdColTitle}
              >
                {thirdColLink ? (
                  <a href={thirdColLink} style={{ textTransform: 'none' }}>
                    {thirdLabel}
                  </a>
                ) : (
                  <>{thirdLabel}</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </PanelTitle>
      </Panel>
    );
  }
}

export default withStyles(styles)(TableHeader);
