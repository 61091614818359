import React from 'react';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill';
import 'fullscreen-api-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter } from 'react-router-dom';
import SkipToContent from 'ui/SkipToContent';

import sentry from '@willing-shared/utils/sentry';

import { enableStandardStyles } from '@/utils/dom';
import AsyncStripeProvider from 'ui/AsyncStripeProvider';
import { ExperimentProvider } from './ui/ExperimentProvider';
// AppRoutes be imported before ClientDataHolder to prevent a circular import
//   issue during the production build.
// TODO: restructure app structure so that the import order is not so fragile.
import AppRoutes from './AppRoutes';
import { ClientDataHolder } from 'ui/components/client-data/ClientDataHolder';
import env from '@willing-shared/utils/env';

import '@willing-shared/assets/stylesheets/averta.scss';
import '!i/favicon.ico';
import '!i/willing-favicon.ico';
import 'normalize.css';
import './main.scss';

sentry.init(window.WillingSentryUrl);
smoothscroll.polyfill();

// Standard styles should be enabled by default so that
//   the app is rendered properly.
enableStandardStyles();

if (!env.isProductionBuild()) {
  var axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <BrowserRouter>
    <AsyncStripeProvider apiKey={window.WillingConfig.stripeKey}>
      <ExperimentProvider>
        <ClientDataHolder>
          <SkipToContent contentId="main-content" />
          <AppRoutes />
        </ClientDataHolder>
      </ExperimentProvider>
    </AsyncStripeProvider>
  </BrowserRouter>,
  document.getElementById('react-root'),
);
