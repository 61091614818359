import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Fab from '@wui/input/fab';
import Link from '@wui/basics/link';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import { PeopleBuilder } from 'models/utils';
import RadioGroup from '@wui/input/radioGroup';
import CustomIcon from '@wui/basics/customIcon';
import TabDivider from '@wui/layout/tabDivider';
import Typography from '@wui/basics/typography';
import GenericError from '@wui/basics/genericError';
import { GenericPerson } from 'models/client-data/main';
import PurePersonChooser from '@c/pure/PurePersonChooser';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { AssetType, ShareDistribution } from 'models/client-data/enums';

import {
  QUICK_GROUPS,
  getQuickGroups,
  QUICK_OPTION_GROUPS,
  QUICK_OPTION_GROUPS_DISPLAY,
} from './helpers';

import { ReactComponent as TrashIcon } from '@a/images/trash.svg';

const NONE = 'None';
const CUSTOM = 'Custom';
const OTHERS = 'The Others in this Group';
const SPECIAL_OPTIONS = [NONE, CUSTOM, OTHERS];

const SOLE_SHARE = '100';
const REMOVE_SHARE = '0';
const CHOOSE_SHARE = 'Choose %';

const QUICK_OPTIONS = Object.entries(QUICK_OPTION_GROUPS_DISPLAY).map(([display, value]) => [
  value,
  display,
]);

export default class BeneficiaryChooser extends React.Component {
  static propTypes = {
    skipped: PropTypes.bool,
    subtitle: PropTypes.string,
    isLastAlternate: PropTypes.bool,
    asset: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    otherBeneficiaries: PropTypes.array,
    onSelection: PropTypes.func.isRequired,
    saveBequest: PropTypes.func.isRequired,
    beneficiaries: PropTypes.array.isRequired,
    shareDistribution: PropTypes.string.isRequired,
    excludedNames: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    skipped: false,
    subtitle: null,
    excludedNames: [],
    isLastAlternate: null,
    otherBeneficiaries: [],
  };

  static contextTypes = {
    testator: PropTypes.object,
  };

  constructor(...args) {
    super(...args);

    const custom = [];
    const { forAlternate, selectedQuickGroupName } = this;
    const { beneficiaries, shareDistribution, skipped } = this.props;

    this.state = {
      custom: [],
      value: skipped ? NONE : selectedQuickGroupName,
    };

    if (!this.madeQuickSelection) {
      // If no quick selection was made, then we
      //   need to initialize the custom fields with
      //   the beneficiaries that were supplied to us.
      if (beneficiaries.length) {
        beneficiaries.forEach(b => {
          // If the share distribution is custom and there is
          //   no share set, then we default the share to the
          //   value that indicates a selection must be made.
          const share =
            shareDistribution === ShareDistribution.CUSTOM
              ? b.sharePercentage || CHOOSE_SHARE
              : ShareDistribution.EQUAL_SHARES;

          custom.push({
            share,
            person: b,
          });
        });

        // If there no available quick option groups and
        //   this is not an alternate beneficiary, then
        //   the user needs to input a custom beneficiary,
        //   so, start off that way.
      } else if (Object.keys(this.quickGroups).length === 0 && !forAlternate) {
        custom.push(this.newCustomInput());
      }
    }

    this.state.custom = custom;
  }

  get forAlternate() {
    const { isLastAlternate } = this.props;

    return isLastAlternate !== null;
  }

  get madeQuickSelection() {
    const { skipped } = this.props;
    const { selectedQuickGroupName } = this;

    return Boolean(selectedQuickGroupName) || skipped;
  }

  get quickGroups() {
    const { testator } = this.context;
    const { excludedNames } = this.props;
    return getQuickGroups(testator, excludedNames);
  }

  get selectedQuickGroupName() {
    const { beneficiaries, shareDistribution } = this.props;

    // If the share distribution is not equal, then there is
    //   no way for a quick group to be selected.
    if (shareDistribution !== ShareDistribution.EQUAL_SHARES) {
      return null;

      // If there is only one beneficary selected, then their
      //   name will match the name of the quick group.
    } else if (beneficiaries.length === 1 && QUICK_OPTION_GROUPS.includes(beneficiaries[0].name)) {
      return beneficiaries[0].name;
    }

    // Otherwise, select the group that has the same number
    //   of people as beneficiaries and has the people with
    //   the same name.
    const groups = this.quickGroups;
    return Object.keys(groups).find(name => {
      const people = groups[name];
      return (
        people.length === beneficiaries.length &&
        beneficiaries.every(b => people.find(p => p.name === b.name))
      );
    });
  }

  get allocatedPercentage() {
    const { custom } = this.state;
    return custom.reduce((total, c) => total + (Number(c.share) || 0), 0);
  }

  get sharesMakeSense() {
    const { customFieldsWithPeopleSet } = this;

    return ShareDistribution.validByShares(customFieldsWithPeopleSet.map(c => c.share));
  }

  get customSaveEnabled() {
    const { customFieldsWithPeopleSet, forAlternate } = this;

    // If choosing primary beneficiaries, at least one
    //   must be selected to continue.
    if (!forAlternate && !customFieldsWithPeopleSet.length) {
      return false;
    }

    return this.sharesMakeSense;
  }

  get assetTitle() {
    const { asset } = this.props;
    const text = AssetType.DISPLAY_NAME_MAP[asset.type];
    return text ? text : 'Gift';
  }
  customPersonSelected = index => person => {
    const { custom } = this.state;
    custom[index].person = person;
    custom[index].showValidationErrors = !person;
    this.updateCustomWithoutProceeding(custom);
  };

  newCustomInput() {
    const defaultShare = this.allocatedPercentage ? CHOOSE_SHARE : ShareDistribution.EQUAL_SHARES;
    return { person: null, share: defaultShare };
  }

  addCustomInput = () => {
    const { custom } = this.state;
    const { beneficiaries } = this.props;

    // If a person is already selected and the user decides
    //   to change to custom input, then add the existing
    //   person as the first selected custom input.
    const moveExistingToCustom =
      beneficiaries.length === 1 &&
      custom.length === 0 &&
      !QUICK_OPTION_GROUPS.includes(beneficiaries[0].name);

    if (moveExistingToCustom) {
      custom.push({
        person: beneficiaries[0],
        share: ShareDistribution.EQUAL_SHARES,
      });
    } else {
      custom.push(this.newCustomInput());
    }

    // We do not use `updateCustomWithoutProceeding` here because
    //   it would remove any placeholders being used for real
    //   estate. If any changes are made to the custom fields
    //   after they are added, then they will be saved then.
    //   If no changes are made, the field would be discarded
    //   as well.
    this.setState({ custom });
  };

  removeCustomInput = index => event => {
    event.preventDefault();
    this.setCustomShare(index)({ target: { value: REMOVE_SHARE } });
  };

  setCustomShare = index => event => {
    const {
      target: { value },
    } = event;
    let { custom } = this.state;

    custom[index].share = value;

    if (value === CHOOSE_SHARE) {
      return;
    } else if (value === REMOVE_SHARE) {
      custom.splice(index, 1);
    } else if (value === SOLE_SHARE) {
      custom = [custom[index]];

      // If equal shares is selected for one person, then
      //   select it for all people, because a mixture does
      //   not make sense.
    } else if (value === ShareDistribution.EQUAL_SHARES) {
      Object.values(custom).forEach(c => (c.share = value));

      // If a percentage was selected and there are only
      //   two people, then set the other person to have
      //   the remaining share.
    } else if (custom.length === 2) {
      custom[index === 0 ? 1 : 0].share = 100 - value;
    }

    // Ensure that a single beneficiary starts off with
    //   equal shares.
    if (custom.length === 1) {
      custom[0].share = ShareDistribution.EQUAL_SHARES;
    }

    this.updateCustomWithoutProceeding(custom);
  };

  get customFieldsWithPeopleSet() {
    const { custom } = this.state;
    return custom.filter(c => c.person);
  }

  saveCustom = (proceedToNextStep = true) => () => {
    const { onSelection } = this.props;

    // We only save those records that have people.
    //   Share distribution validity is based on
    //   the records that have a person associated
    //   only.
    onSelection(this.customFieldsWithPeopleSet, proceedToNextStep);
  };

  updateCustomWithoutProceeding(custom) {
    const { asset } = this.props;
    const { forAlternate } = this;

    // If adding primary beneficiaries for the residual asset,
    //   skip saving until the user manually clicks the button.
    //   This prevents automatic breakouts from happening with
    //   incomplete information.
    const stateCallback =
      asset.type === AssetType.RESIDUAL && !forAlternate ? () => {} : this.saveCustom(false);

    this.setState({ custom }, stateCallback);
  }

  quickSelectPeople = people => {
    const { onSelection } = this.props;

    onSelection(
      people.map(p => ({
        person: p,
        share: ShareDistribution.EQUAL_SHARES,
      })),
    );
  };

  blurOrFocusCustomInput = (index, focused) => () => {
    const { custom } = this.state;
    custom[index].focused = focused;
    this.updateCustomWithoutProceeding(custom);
  };

  shareOptions(beneficiary) {
    const totalAllocated = this.allocatedPercentage;
    const currentlyAllocated = Number(beneficiary.share) || 0;

    const min = Math.min(99, 100 - totalAllocated + currentlyAllocated);
    const max = Math.max(min, currentlyAllocated);

    const options = {
      [ShareDistribution.EQUAL_SHARES]: 'Equal',
      [SOLE_SHARE]: '100%',
    };

    // Only make this value available if it is
    //   already the value of the custom field.
    if (beneficiary.share === CHOOSE_SHARE) {
      options[CHOOSE_SHARE] = CHOOSE_SHARE;
    }

    for (let i = max; i >= 1; i--) {
      options[i] = `${i}%`;
    }

    options[REMOVE_SHARE] = '0%';

    return options;
  }

  renderCustomAdditionalColumns(index) {
    const { custom } = this.state;
    const count = custom.length;

    // There is no need to have additional options
    //   for a single custom input.
    if (count === 1) {
      return null;
    }

    const current = custom[index];
    const error =
      (this.showSharesError || current.share === CHOOSE_SHARE) && Boolean(current.person);

    return (
      <React.Fragment>
        <Grid xs sm={3} item>
          <Textbox
            error={error}
            label="Share"
            hideEmptyOption
            value={String(current.share)}
            options={this.shareOptions(current)}
            onChange={this.setCustomShare(index)}
          />
        </Grid>
        <Hidden smUp>
          <Grid item xs={1} />
        </Hidden>
        <Grid item sm={3}>
          <Typography align="right" variant="caption">
            {index + 1} of {count}
            <br />
            <Link href="#" color="inherit" onClick={this.removeCustomInput(index)}>
              <TrashIcon />
              Delete
            </Link>
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  renderCustomInput = index => {
    const { custom } = this.state;
    const { forAlternate } = this;
    const { beneficiaries, excludedNames } = this.props;
    const { clientData } = this.context.testator;

    const last = index === custom.length - 1;
    const width = custom.length === 1 ? 12 : 6;

    const input = custom[index];
    const otherCustomNames = custom
      .filter((c, i) => i !== index && c.person)
      .map(c => c.person.name);
    const exclude = [...excludedNames, ...otherCustomNames];
    const eligible = PeopleBuilder.getAll(clientData).filter(p => !exclude.includes(p.name));

    // The name is required if we are not selecting
    //   alternates and there are no existing beneficiaries
    //   selected already.
    const required = !forAlternate && beneficiaries.length === 0;

    const showError =
      required &&
      // Don't show when the control is focused because
      //   the person is in the middle of editing and
      //   it is confusing.
      !input.focused &&
      // Don't show if the control does not have
      //   validation errors enabled.
      input.showValidationErrors &&
      // Don't show if a person is selected.
      !input.person;

    const error = showError ? 'Please select a person.' : null;

    return (
      <Grid container key={index} spacing={1} alignItems="center">
        <Grid item xs={12} sm={width}>
          <DimensionLimiter h={400}>
            <PurePersonChooser
              error={error}
              label="Full Name"
              allNames={exclude}
              initialPeople={eligible}
              TextFieldComponent={Textbox}
              selectedPerson={input.person}
              onSelect={this.customPersonSelected(index)}
              onBlur={this.blurOrFocusCustomInput(index, false)}
              onFocus={this.blurOrFocusCustomInput(index, true)}
            />
          </DimensionLimiter>
        </Grid>

        {this.renderCustomAdditionalColumns(index)}

        {!last && (
          <Hidden smUp>
            <TabDivider halfMargin />
          </Hidden>
        )}
      </Grid>
    );
  };

  get showSharesError() {
    const { customFieldsWithPeopleSet } = this;

    // If the person has not chosen a share yet, then
    //   don't show the validation error.
    if (customFieldsWithPeopleSet.some(c => c.share === CHOOSE_SHARE)) {
      return false;
    }

    return !this.sharesMakeSense;
  }

  closeCustom = () => {
    let value = null;
    const { customFieldsWithPeopleSet } = this;

    // If only one custom person is selected, then we
    //   can set the value to their name so that they
    //   are still selected in the quick option groups.
    if (customFieldsWithPeopleSet.length === 1) {
      value = customFieldsWithPeopleSet[0].person.name;
    }

    this.setState({ value, custom: [] });
  };

  renderCustom(options) {
    const { custom } = this.state;

    return (
      <React.Fragment>
        {[...Array(custom.length).keys()].map(this.renderCustomInput)}

        {this.showSharesError && (
          <React.Fragment>
            <GenericError message="Please make sure all shares for named beneficiaries add up to 100%." />

            <TabDivider dashed halfMargin />
          </React.Fragment>
        )}

        <Fab icon={AddIcon} onClick={this.addCustomInput} label="Add Another Beneficiary" />

        {this.renderCTA(options)}
      </React.Fragment>
    );
  }

  renderCTA(options) {
    const { isLastAlternate, saveBequest } = this.props;
    const dividerProps = {
      dashed: !isLastAlternate,
      expanded: isLastAlternate,
    };
    const filteredOptions = options.filter(([v]) => !SPECIAL_OPTIONS.includes(v));

    // Only show the back button if there are option
    //   groups available.
    const showBackButton = Boolean(filteredOptions.length);

    return (
      <React.Fragment>
        <TabDivider {...dividerProps} halfMargin />

        <Typography align="right" component="div">
          <Grid container alignItems="center" justify="space-between">
            {showBackButton && (
              <Grid item>
                <Button size="small" variant="text" onClick={this.closeCustom}>
                  <CustomIcon
                    width={14}
                    height={14}
                    src={ArrowBackIcon}
                    color={theme => theme.palette.blue.textboxFocus}
                  />
                  Back
                </Button>
              </Grid>
            )}
            <Grid xs item align="right">
              <Button
                color="primary"
                variant="contained"
                disabled={!this.customSaveEnabled}
                onClick={isLastAlternate ? saveBequest : this.saveCustom()}
                className={isLastAlternate ? 'bequest-save-button' : 'bequest-beneficiary-continue'}
                aria-label={`${this.assetTitle} ${isLastAlternate ? 'Save' : 'Continue'}`}
              >
                {isLastAlternate ? 'Save' : 'Continue'}
              </Button>
            </Grid>
          </Grid>
        </Typography>
      </React.Fragment>
    );
  }

  onQuickOptionChange = value => {
    const { onSelection, otherBeneficiaries } = this.props;

    if (QUICK_OPTION_GROUPS.includes(value)) {
      this.quickSelectPeople([new GenericPerson({ name: value })]);
    } else if (value === NONE) {
      onSelection([]);
    } else if (value === OTHERS) {
      value = null;
      this.quickSelectPeople(otherBeneficiaries);
    } else if (value === CUSTOM) {
      value = null;
      this.addCustomInput();
    } else {
      this.quickSelectPeople(this.quickGroups[value]);
    }

    this.setState({ value });
  };

  continueWithCurrentSelection = () => {
    const { skipped } = this.props;
    const { selectedQuickGroupName } = this;

    let value = selectedQuickGroupName;

    if (skipped) {
      value = NONE;
    }

    this.onQuickOptionChange(value);
  };

  renderCallToAction() {
    const { isLastAlternate } = this.props;

    if (isLastAlternate || !this.madeQuickSelection) {
      return null;
    }

    return (
      <React.Fragment>
        <TabDivider dashed halfMargin />

        <Typography align="right" component="div">
          <Button
            color="primary"
            variant="contained"
            onClick={this.continueWithCurrentSelection}
            className="bequest-beneficiary-continue"
            aria-label={`${this.assetTitle} Continue`}
          >
            Continue
          </Button>
        </Typography>
      </React.Fragment>
    );
  }

  get options() {
    const { forAlternate } = this;
    const { testator } = this.context;
    const { asset, otherBeneficiaries } = this.props;
    const primaries = testator.getPrimaryBeneficiariesForAsset(asset);
    const primaryIsSpouse =
      primaries.length === 1 && primaries[0].personUUID === testator.spouse.personUUID;

    const quickGroupKeys = Object.keys(this.quickGroups);
    const options = quickGroupKeys.map(name => [name, name]);
    const childrenOptionsMissing = !QUICK_GROUPS.some(g => quickGroupKeys.includes(g));

    // The quick options are only added when:
    //   - The chooser is for an alternate beneficiary (primary
    //     doesn't make sense because there is no context for
    //     'his or her'.
    const addQuickOptions =
      forAlternate &&
      // The asset does not need a beneficiary address, because
      //   if one is needed, adding for a group of people doesn't
      //   make sense.
      !asset.needsBeneficiaryAddress &&
      // This is not a residual that includes an asset that needs a beneficiary address
      (asset.type !== AssetType.RESIDUAL ||
        testator.ownAssets.every(a => !a.needsBeneficiaryAddress)) &&
      // The quick group choices for children are not present
      //   for the spouse because adding them again is redundant.
      (!primaryIsSpouse || childrenOptionsMissing);

    // TODO: If the quick options ever have anything else in
    //   the list, this will likely need to be changed to only
    //   exclude 'his or her children'.
    if (addQuickOptions) {
      options.push(...QUICK_OPTIONS);
    }

    if (otherBeneficiaries.length > 1) {
      options.push([OTHERS, OTHERS]);
    }

    if (forAlternate) {
      options.push([NONE, NONE]);
    }

    options.push([CUSTOM, CUSTOM]);

    return options;
  }

  renderQuickOptions(options) {
    const { value } = this.state;

    return (
      <React.Fragment>
        <RadioGroup
          pulseFirstOption={!value}
          value={value}
          options={options}
          withDeselectedBackground={false}
          onChange={this.onQuickOptionChange}
          className="quick-options"
          label=""
        />

        {this.renderCallToAction()}
      </React.Fragment>
    );
  }

  render() {
    const { options } = this;
    const { custom } = this.state;
    const { isLastAlternate, subtitle, title } = this.props;

    // If there are no custom options, then the CTA should
    //   be rendered for the last alternate. When custom
    //   options are present, rendering the CTA is
    //   handled by renderCustom. Otherwise, the CTA is
    //   rendered by the bequest and not by this
    //   component.
    const forceRenderCTA = !custom.length && isLastAlternate;

    return (
      <React.Fragment>
        <Typography variant="h6">{title}</Typography>

        {subtitle && <Typography>{subtitle}</Typography>}

        <Spacer v={8} />

        {custom.length ? this.renderCustom(options) : this.renderQuickOptions(options)}

        {forceRenderCTA && this.renderCTA([])}
      </React.Fragment>
    );
  }
}
