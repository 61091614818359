import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';

import Layout from '@c/layout/Layout';
import { AppLocation } from 'utils/enums';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

import UnlimitedChanges from '@a/images/checkout-v6/unlimited-changes.svg';
import GreenTick from '@a/images/green-tick.svg';

import { digitalEstatePlanningService, trustService } from '@willing-shared/utils/env';

import { API } from 'API';

const styles = {
  listItem: {
    margin: 20,
  },
  iconContainer: {
    textAlign: 'right',
    padding: 30,
  },
  icon: {
    width: 25,
  },
};

const ImageListItem = ({ image, title, text, classes, titleVariant }) => (
  <Grid container direction="row" className={classes.listItem}>
    <Grid item xs={3} className={classes.iconContainer}>
      <img src={image} alt="" role="presentation" className={classes.icon} />
    </Grid>
    <Grid container item direction="column" xs={9}>
      <Typography variant={titleVariant}>{title}</Typography>
      <Typography variant="body1">{text}</Typography>
    </Grid>
  </Grid>
);

ImageListItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

ImageListItem.defaultProps = {
  titleVariant: 'h5',
  classes: {},
};

const StyledImageListItem = withStyles(styles)(ImageListItem);

class Bundle extends ClientDataReferer {
  componentDidMount() {
    if (trustService) {
      API.sendUserEvent('TRUST_BUNDLE_SEEN');
    }
  }

  render() {
    const { probateDocumentsRestricted } = this.serverData;
    const title = trustService
      ? 'Creating your trust and estate plan'
      : 'Creating your estate plan';
    const product = trustService ? 'a trust' : 'an estate plan';
    const trustAlong = trustService ? 'trust along with the ' : '';
    const nextSteps = `In the next steps, you'll be guided through an online process where you can create ${product}
      tailored to your wishes and the laws of your state. The ${trustAlong}following documents are included.`;
    const hideProbate = probateDocumentsRestricted || trustService || digitalEstatePlanningService;

    return (
      <Layout wide={true} appLocation={AppLocation.ABOUT_YOU}>
        <ImageListItem image={UnlimitedChanges} title={title} titleVariant="h2" text={nextSteps} />
        {trustService && (
          <StyledImageListItem
            image={GreenTick}
            title="Revocable living trust"
            text="Keep your real estate out of the probate process and have it pass directly to the beneficiaries of your choosing with a Revocable Living Trust."
          />
        )}
        <StyledImageListItem
          image={GreenTick}
          title="Last will and testament"
          text="Leave property to your loved ones and choose guardians for minor children."
        />
        <StyledImageListItem
          image={GreenTick}
          title="Advance healthcare directive"
          text="Plan for a medical emergency, select your medical care preferences and designate a healthcare proxy."
        />
        <StyledImageListItem
          image={GreenTick}
          title="Financial power of attorney"
          text="Choose someone who can manage your finances in case of an emergency."
        />
        {hideProbate || (
          <StyledImageListItem
            image={GreenTick}
            title="Probate avoidance documents"
            text="Keep your home out of the probate process and have it pass directly to the beneficiaries of your choosing with either a Transfer on Death Deed or Revocable Living Trust, depending on your state."
          />
        )}
      </Layout>
    );
  }
}

export default Bundle;
