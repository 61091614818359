import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import Panel from '@wui/layout/panel';
import AccountNav from './AccountNav';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import CloseButton from '@wui/basics/closeButton';
import TipsAndSummarySidebar from '@c/TipsAndSummarySidebar';

const styles = theme => ({
  backdrop: {
    backgroundColor: theme.palette.grey.modalBackground,
  },
  content: {
    position: 'absolute',
    top: 66,
    right: 16,
    left: 16,
    bottom: 0,
    paddingBottom: 32,
    overflow: 'auto',

    [theme.breakpoints.notPhone]: {
      left: 'auto',
      width: 386,
    },
  },
  padded: {
    padding: 24,
    margin: 0,
  },
  panel: {
    '& button[aria-label="close"]': {
      zIndex: 1,
    },
  },
});

class MobileNavMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    showGuide: PropTypes.bool,
  };

  static defaultProps = {
    showGuide: false,
  };

  static contextTypes = {
    headerHeight: PropTypes.shape({}),
  };

  render() {
    const { classes, onClose, open, showGuide } = this.props;
    const {
      headerHeight: { value: headerHeight },
    } = this.context;

    const guideSection = (
      <Panel
        className={classes.panel}
        round
        paddingless
        noOverflow
        onClick={e => e.stopPropagation()}
      >
        <CloseButton onClick={onClose} position="top" />
        <div className={classes.padded}>
          <Typography variant="h4">Guide</Typography>
        </div>
        <TipsAndSummarySidebar borderless includeProgress />
        <Spacer v={24} />
      </Panel>
    );

    return (
      <Modal
        onClose={onClose}
        open={open}
        disableAutoFocus
        BackdropProps={{ classes: { root: classes.backdrop } }}
      >
        <div className={classes.content} style={{ top: headerHeight + 16 }} onClick={onClose}>
          {showGuide && guideSection}
          <AccountNav close={onClose} />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(MobileNavMenu);
