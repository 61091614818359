import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Link from '@wui/basics/link';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import CustomIcon from '@wui/basics/customIcon';
import { getEmail, getPhoneNumber } from 'utils';

import { ReactComponent as PhoneIcon } from '@a/images/phone.svg';
import { ReactComponent as EmailIcon } from '@a/images/email.svg';

const styles = {
  root: {
    '&:hover': {
      fontWeight: 'bold',
    },
  },
};

class WuiPhoneLink extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
  };
  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
  };

  render() {
    const { classes } = this.props;
    const {
      clientDataHolder: { clientData, serverData, supportInfo },
    } = this.context;

    const phoneInfo = {
      href: getPhoneNumber(clientData, serverData, 'link'),
      content: getPhoneNumber(clientData, serverData, 'display'),
      label: `${getPhoneNumber(clientData, serverData, 'display')} phone number`,
      icon: PhoneIcon,
    };
    const emailInfo = {
      href: `mailto:${getEmail()}`,
      content: getEmail(),
      label: `${getEmail()} email`,
      icon: EmailIcon,
    };

    const info = supportInfo.showSupportPhoneNumber ? phoneInfo : emailInfo;

    return (
      <Typography className={classes.root}>
        <Link
          href={info.href}
          aria-label={info.label}
          color="inherit"
          underline="none"
          style={{ whiteSpace: 'nowrap', height: '100%' }}
        >
          <CustomIcon
            role="presentation"
            src={info.icon}
            color={theme => theme.palette.text.primary}
          />
          <Spacer h={4} />
          {info.content}
        </Link>
      </Typography>
    );
  }
}

export default withStyles(styles)(WuiPhoneLink);
