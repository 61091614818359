import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withIsMobileDisplay from 'hocs/withIsMobileDisplay';

import ColumnFooter from './ColumnFooter';

const verticalPadding = 64;

export const paddedStyleRules = {
  withLeftFooter: {
    // This is a helper class that others use to
    //   base their rules on.
  },

  padded: {
    padding: [[verticalPadding, 0]],

    '&$withLeftFooter': {
      paddingBottom: 0,
    },
  },
};

const styles = theme => ({
  ...paddedStyleRules,

  wide1: {
    // This is a helper class that others use to
    //   base their rules on.
  },
  wide2: {
    // This is a helper class that others use to
    //   base their rules on.
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  leftGutter: {
    minWidth: theme.layout.leftSideMobileGutter,

    [theme.breakpoints.up('sm')]: {
      minWidth: 48,
    },

    '$root:not($wide1):not($wide2) &': {
      [theme.breakpoints.up('sm')]: {
        width: '30.75%',
      },
    },
  },
  leftContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    '$root$wide1 &': {
      maxWidth: 700,
      margin: [[0, 'auto']],
    },

    '$root$wide2 &': {
      maxWidth: 970,
      margin: [[0, 'auto']],
    },

    '$root:not($wide1):not($wide2) &': {
      maxWidth: 400,
    },

    '$withLeftFooter & > :last-child': {
      marginTop: verticalPadding,
    },

    [theme.breakpoints.up('sm')]: {
      minWidth: 320,
    },
  },
  leftInner: {
    flexGrow: 1,
  },
});

class TwoColumnContentLeft extends React.Component {
  static propTypes = {
    padded: PropTypes.bool,
    leftFooter: PropTypes.bool,
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({}).isRequired,
    wide: PropTypes.oneOf([false, true, 1, 2]),
  };

  static defaultProps = {
    wide: false,
    padded: true,
    leftFooter: false,
  };

  render() {
    const { wide, padded, classes, children, leftFooter } = this.props;

    const showLeftFooter = leftFooter && !this.isTabletOrSmallerDisplay;
    const wideClassName = `wide${Number(wide)}`;

    const className = classNames(classes.root, {
      [classes.padded]: padded,
      [classes[wideClassName]]: wide,
      [classes.withLeftFooter]: showLeftFooter,
    });

    return (
      <div className={className}>
        <div className={classes.leftGutter} />
        <div className={classes.leftContent} role="main" id="main-content">
          <div className={classes.leftInner}>{children}</div>
          {showLeftFooter && <ColumnFooter />}
        </div>
        <div className={classes.leftGutter} />
      </div>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(TwoColumnContentLeft));
