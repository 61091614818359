import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@wui/basics/link';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import RadioGroup from '@wui/input/radioGroup';
import BoundTextField from '@c/BoundTextField';
import Typography from '@wui/basics/typography';
import { Asset } from 'models/client-data/assets';
import GenericError from '@wui/basics/genericError';
import { AssetType } from 'models/client-data/enums';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import PageVisit from 'ui/components/wizard/PageVisit';
import BequestStep from './BequestStep';
import RealEstate from 'ui/sections/about-you/pages/RealEstate';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';

export default class AssetEditor extends React.Component {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    updateStep: PropTypes.func.isRequired,
    realEstateBeneficiary: PropTypes.object.isRequired,
  };

  static contextTypes = {
    history: PropTypes.object,
    testator: PropTypes.object,
  };

  get nextStepEnabled() {
    const { asset } = this.props;

    return asset.type && Object.keys(asset.requiredFields).every(field => asset[field]);
  }

  get assetTitle() {
    const { asset } = this.props;
    const text = AssetType.DISPLAY_NAME_MAP[asset.type];
    return text ? text : 'Gift';
  }

  get availableRealEstate() {
    const { testator } = this.context;
    const { ownAndJointRealEstates } = testator;

    // If an asset has an address, and hasn't already been selected
    //   as part of another bequest, it is available for selection.
    return ownAndJointRealEstates.filter(
      r => r.hasAddress && !testator.getPrimaryBeneficiariesForAsset(r).length,
    );
  }

  swapWithExistingRealEstate(replacementUUID) {
    const { testator } = this.context;
    const { asset, realEstateBeneficiary } = this.props;
    const replacement = this.availableRealEstate.find(r => r.assetUUID === replacementUUID);

    // If the asset and the replacement are the same,
    //   then there is no need to do anything.
    if (asset.assetUUID !== replacementUUID) {
      const { assets } = testator;

      // If the asset is an existing real estate asset,
      //   then we just want to hide it.
      if (asset.isRealEstate && asset.hasAddress) {
        testator.removeBequestsFromAsset(asset);

        // Otherwise, delete it.
      } else {
        assets.splice(assets.indexOf(asset), 1);
      }

      // Add a new beneficiary to the replacement
      //   so it becomes visible.
      replacement.primaryBeneficiaries.push(realEstateBeneficiary);

      // We add this temporary property on the asset to prevent
      //   the bequest interface from assuming that the user has
      //   previously skipped adding alternates.
      replacement.freshlyAddedRealEstate = true;

      // Move the replacement to the end of the assets array
      //   so that when it becomes visible, it is at the top
      //   of the list.
      assets.push(assets.splice(assets.indexOf(replacement), 1)[0]);
    }

    // Since we are changing the asset, we must
    //   update the step so it is tracked in the
    //   parent component. If there is no
    //   replacement, update the step for the
    //   existing asset to continue.
    this.nextStep(replacement)();
  }

  typeChanged = (previousType, newType) => {
    const { testator } = this.context;
    const { asset, realEstateBeneficiary } = this.props;

    // Clear any fields that may have been set for the asset,
    //   unless switching from a real estate asset. Those do
    //   not need to be cleared because they can't be changed
    //   on this page and must be changed in the real estate
    //   editor.
    if (previousType !== AssetType.REAL_ESTATE) {
      Object.keys(Asset.requiredFields(previousType)).forEach(field => {
        asset.setRawValue(field, '');
      });
    }

    // We add or remove a primary beneficiary because they
    //   are used to determine visibility of the asset for
    //   real estate only.
    if (newType === AssetType.REAL_ESTATE) {
      // If the user has only one real estate asset, then
      //   remove this asset and make the existing one
      //   visible.
      if (this.availableRealEstate.length === 1) {
        this.swapWithExistingRealEstate(this.availableRealEstate[0].assetUUID);

        // Otherwise, just make this one visible.
      } else {
        asset.primaryBeneficiaries.push(realEstateBeneficiary);
      }

      // If the previous type was real estate, we added a
      //   beneficiary to make it visible, so that must be
      //   removed now.
    } else if (previousType === AssetType.REAL_ESTATE) {
      testator.removeBequestsFromAsset(asset);
    }
  };

  nextStep = (overrideAsset = null) => () => {
    const { asset, updateStep } = this.props;

    asset.setRawValue('description', Asset.removeInvalidPrefixes(asset.description));
    updateStep(overrideAsset || asset, BequestStep.EDIT_PRIMARIES);
  };

  get realEstatePageURL() {
    return new PageVisit(SectionAboutYou, RealEstate).url;
  }

  onRealEstateSelect = value => {
    if (!value) {
      const { history } = this.context;
      history.push(this.realEstatePageURL);
    } else {
      this.swapWithExistingRealEstate(value);
    }
  };

  renderField = ([field, displayName]) => {
    const { asset } = this.props;

    const inputType = ['estimatedValue', 'vehicleYear'].includes(field) ? 'number' : 'text';

    // The key includes the asset type to ensure
    //   that validation errors are cleared when
    //   the field props remain the same during
    //   rendering (like for the description
    //   field).
    return (
      <DimensionLimiter h={400} key={`${field}-${asset.type}`}>
        <BoundTextField
          obj={asset}
          path={field}
          type={inputType}
          label={displayName}
          component={Textbox}
        />
      </DimensionLimiter>
    );
  };

  renderPropertyEditorMessage() {
    const link = (
      <Link component={RouterLink} to={this.realEstatePageURL}>
        Real Estate
      </Link>
    );

    return <React.Fragment>To select a new property, please visit the {link} page.</React.Fragment>;
  }

  sortRealEstateOptions([a], [b]) {
    // The 'Add Another' option should
    //   always be last.
    if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    }

    // Otherwise, sort normally.
    const aUpper = a.toUpperCase();
    const bUpper = b.toUpperCase();

    if (aUpper < bUpper) {
      return 1;
    } else if (aUpper > bUpper) {
      return -1;
    }

    return 0;
  }

  renderRealEstateEditor() {
    const { asset } = this.props;

    // If the asset already has an address, and there
    //   are no other real estate assets available to
    //   choose, show a message telling the user to
    //   edit real estate on the appropriate page.
    if (asset.hasAddress && this.availableRealEstate.length === 0) {
      return (
        <React.Fragment>
          <Typography variant="h6">{asset.address.street}</Typography>

          <Typography>{this.renderPropertyEditorMessage()}</Typography>

          <Spacer v={20} />

          <Button
            color="primary"
            variant="contained"
            onClick={this.nextStep()}
            className="bequest-asset-continue"
            aria-label={`${this.assetTitle} Continue`}
          >
            Continue
          </Button>
        </React.Fragment>
      );

      // If there are no existing real estate
      //   properties, then show an error message.
    } else if (this.availableRealEstate.length === 0) {
      return <GenericError message={this.renderPropertyEditorMessage()} />;
    }

    // Otherwise, let the user select from their
    //   existing properties and have an option
    //   for them to add a new property on the
    //   real estate page.
    const options = this.availableRealEstate.map(r => [r.assetUUID, r.address.street]);

    // If the selected asset has an address,
    //   then it needs to be added to the list
    //   of options because it is not considered
    //   available.
    if (asset.hasAddress) {
      options.push([asset.assetUUID, asset.address.street]);
    }

    options.push([null, 'Add Another']);

    return (
      <RadioGroup
        value={asset.assetUUID}
        withDeselectedBackground={false}
        onChange={this.onRealEstateSelect}
        options={options.sort(this.sortRealEstateOptions)}
      />
    );
  }

  renderEditor() {
    const { asset } = this.props;

    return (
      <React.Fragment>
        {Object.entries(asset.requiredFields).map(this.renderField)}

        <Spacer v={16} />

        <Button
          color="primary"
          variant="contained"
          onClick={this.nextStep()}
          disabled={!this.nextStepEnabled}
          className="bequest-asset-continue"
          aria-label={`${this.assetTitle} Continue`}
        >
          Continue
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { asset } = this.props;

    // Once a real estate asset is selected from
    //   the list of available properties, we don't
    //   allow the user to change the asset type
    //   anymore.
    const canChangeType = !asset.isRealEstate || !asset.hasAddress;

    return (
      <React.Fragment>
        {canChangeType && (
          <DimensionLimiter h={400}>
            <BoundTextField
              obj={asset}
              path="type"
              label="Gift Type"
              component={Textbox}
              onChange={this.typeChanged}
              autoFocus
            />
          </DimensionLimiter>
        )}

        {asset.isRealEstate ? this.renderRealEstateEditor() : this.renderEditor()}
      </React.Fragment>
    );
  }
}
