import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CheckoutV6 from 'material-ui/pages/checkout-v6/CheckoutV6';
import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import { Urls } from 'urls';
import LogoMetLife from '@willing-shared/assets/images/mlp-logo.svg';

import List from './List';

import './MobileNav.scss';

const MobileNav = ({ logoAlt, handleLogoClick }) => {
  const [dropOpen, setDropOpen] = useState(false);
  const location = useLocation();

  const isSmallScreen = useMediaQuery('(max-width: 999px)');

  useEffect(() => setDropOpen(false), [location]);

  const handleDropClick = () => setDropOpen(v => !v);
  const closeDrop = () => setDropOpen(false);

  const isOnCheckout =
    location.pathname === CheckoutV6.url || location.pathname === SubscriptionPlanPricing.url;

  const isOnRegister =
    location.pathname.startsWith(Urls.registerWithInfo) || location.pathname === Urls.register;

  const dropClass = classNames('dropdown', { 'dropdown--hidden': !dropOpen });
  const className = classNames('mobile-navbar', { 'mobile-navbar--white': isOnCheckout });
  const brandClass = classNames('mobile-navbar__brand');

  return (
    <div className="mobilenav-container">
      <nav className={className}>
        <div className={isOnCheckout ? 'mobile-navbar__left' : 'mobile-navbar__center'}>
          <Link to="/" onClick={handleLogoClick} className="mobile-navbar__brand-link">
            <img src={LogoMetLife} className={brandClass} alt={logoAlt} />
          </Link>
        </div>

        {!isOnRegister && (
          <IconButton
            aria-label="Hamburger menu"
            aria-expanded={dropOpen}
            aria-hidden={dropOpen}
            onClick={handleDropClick}
            className="mobile-navbar__right"
          >
            <span className="mobile-navbar__hamburger-icon" />
          </IconButton>
        )}
      </nav>

      {isSmallScreen && (
        <Modal open={dropOpen} BackdropProps={{ className: 'backdrop' }}>
          <div className={dropClass} onClick={closeDrop}>
            <IconButton
              aria-label="Close menu"
              onClick={closeDrop}
              className="dropdown__close-button"
            />
            <List />
          </div>
        </Modal>
      )}
    </div>
  );
};

MobileNav.propTypes = {
  logoAlt: PropTypes.string.isRequired,
  handleLogoClick: PropTypes.func.isRequired,
};

export default observer(MobileNav);
