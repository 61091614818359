import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SectionEntry from 'material-ui/components/dashboard/SectionEntry';
import TableHeader from 'material-ui/components/dashboard/TableHeader';

import { useGlobalContext } from 'hooks';

const SectionsDisplay = ({ sections, ...props }) => {
  const { clientData } = useGlobalContext();
  const firstIncompleteIndex = sections.findIndex(s => !clientData.isSectionValid(s));

  return (
    <React.Fragment>
      <div role="table">
        <TableHeader {...props} />
        {sections.map((s, idx) => (
          <SectionEntry key={idx} section={s} special={firstIncompleteIndex === idx} />
        ))}
      </div>
    </React.Fragment>
  );
};

SectionsDisplay.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default observer(SectionsDisplay);
