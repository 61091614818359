import React from 'react';
import { Link } from 'react-router-dom';
import { PrevNextNavigation } from 'ui/components/wizard/PrevNextNavigation';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import ValidationDisplay from 'ui/components/ValidationDisplay';
import PropTypes from 'prop-types';
import { ForSpouseContext } from 'contexts';

import './WizardPage.scss';

export default class WizardPage extends ClientDataReferer {
  static propTypes = {
    goToPrev: PropTypes.func.isRequired,
    goToNext: PropTypes.func.isRequired,
    fullUrl: PropTypes.string.isRequired,
    keyParam: PropTypes.string,
    showCloseButton: PropTypes.bool,
    closeUrl: PropTypes.string,
    wizardPageClass: PropTypes.string,
  };

  static childContextTypes = {
    page: PropTypes.object,
    testator: PropTypes.object,
  };

  static baseUrl;
  static showPrevNextButtons = true;
  static forSpouse = false;
  static showFooter = true;
  static enterNavigation = true;
  static isMaterial = false;
  static hideNav = false;
  static hideZendesk = false;

  // This should be set to `true` if the page can
  //   be skipped because it only contains information
  //   and no actions for the user.
  static noUserActions = false;
  static cardwrapperClass = '';
  static cardClass = '';

  static generateUrl(forSpouse) {
    return (forSpouse ? 'spouse/' : '') + this.baseUrl;
  }

  static get url() {
    return this.generateUrl(this.forSpouse);
  }

  // Returns a function that returns the content that
  //   should be displayed if this page uses a custom
  //   display. If the custom display is disabled (but
  //   can exist), return `null`. If a custom display
  //   is never used, return `undefined`.
  // eslint-disable-next-line no-unused-vars
  static customDisplay(experimentContext) {
    // Intentionally left blank so `undefined` is
    //   returned.
  }

  constructor(props, context) {
    super(props, context);

    this.state = { postError: false, errorMessage: null };
  }

  // Override this if you like, but make sure to include
  //   anything that this passes by calling the `super`
  //   method.
  getPageContext() {
    return {
      goToNext: this.goToNext.bind(this),
      firstControlWithError: this.firstControlWithError.bind(this),
      generateUrl: this.constructor.generateUrl.bind(this.constructor),
    };
  }

  getChildContext() {
    return {
      testator: this.testator,
      page: this.getPageContext(),
    };
  }

  goToNext() {
    this.props.goToNext();
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.constructor.enterNavigation) {
      this.keyDownListener = e => {
        if (
          e.charCode === 13 &&
          !e.target.classList.contains('text-input--downshift') &&
          !e.target.classList.contains('go-to-next__cancel') &&
          !e.target.classList.some(cls => cls.startsWith('MuiTabs'))
        ) {
          this.goToNext();
        }
      };
      document.addEventListener('keypress', this.keyDownListener);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.keyDownListener);
    super.componentWillUnmount();
  }

  get testator() {
    return this.constructor.forSpouse ? this.clientData.spouse : this.clientData;
  }

  set testator(_) {
    // Just ignore the value since it's ClientDataReferrer trying to give us undefined
  }

  render() {
    const customDisplayRenderer = this.constructor.customDisplay(this.context.experiments);

    if (customDisplayRenderer) {
      return customDisplayRenderer(this);
    }

    return this.renderPageContent();
  }

  renderPageContent() {
    return (
      <ForSpouseContext.Provider value={this.constructor.forSpouse}>
        {this.props.showCloseButton && (
          <Link to={this.props.closeUrl} className="wizard-close wizard-close-x" />
        )}
        <main id="main-content" style={{ height: '100%' }}>
          {this.renderPage()}
        </main>

        {this.constructor.showPrevNextButtons && (
          <PrevNextNavigation
            onGoToPrev={this.props.goToPrev}
            onGoToNext={this.goToNext.bind(this)}
          />
        )}

        {this.state.postError && <ValidationDisplay errors={[this.state.errorMessage]} />}
      </ForSpouseContext.Provider>
    );
  }
}
