import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: [theme.palette.primary.main, '!important'],
    color: ['white', '!important'],
    height: 42,
    left: '50%',
    padding: 8,
    position: 'absolute',
    transform: 'translate(-50%,-100%)',
    transition: 'transform 0.3s',
    zIndex: theme.zIndex.tooltip,
    textTransform: ['none', '!important'],
    textDecoration: ['underline', '!important'],

    '&:focus': {
      transform: 'translate(-50%, 0%)',
    },
  },
}));

const SkipToContent = ({ contentId }) => {
  const classes = useStyles();

  return (
    <a href={`#${contentId}`} className={classes.root}>
      Skip to content
    </a>
  );
};

SkipToContent.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default observer(SkipToContent);
